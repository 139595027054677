@import "_variables.scss";

input[type="button"],
.button {
    border: 1px solid $tuerkis;
    background-color: $white;
    color: $tuerkis !important;

    border-radius: 20px;
    padding: 10px 25px;

    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;

    position: relative;

    width: auto;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    -webkit-box-shadow: 0px 0px 5px 0px rgba($black, 0.1);
    box-shadow: 0px 0px 5px 0px rgba($black, 0.1);

    transition: $transition-slow;

    &:disabled {
        color: $grey-dark !important;

        &:hover {
            border-color: $grey-light !important;
            color: $grey-dark !important;
        }
    }

    &.inverted {
        background-color: $tuerkis;
        color: $white !important;

        &:hover {
            background-color: $white;
            color: $tuerkis !important;
        }
    }

    &.button-grey {
        border-color: $grey-middle;
        color: $header-dark !important;

        &:hover {
            border-color: $tuerkis;
            color: $white;
        }
    }

    &.search {
        margin-top: 2px;
        margin-left: 0;
        width: auto;
        padding-left: 40px;

        background-image: url(../images/icons/t_search.png);
        background-repeat: no-repeat;
        background-position: left 10px center;

        &:hover {
            background-image: url(../images/icons/w_search.png);
        }
    }

    &.button-formulardownload {
        position: relative;

        padding-left: 50px;
        display: inline-block;
        margin-bottom: 10px;

        height: 40px;

        &::before {
            content: "";

            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 38px;

            background-image: url(../images/sprites/icons.png);
            background-repeat: no-repeat;
            background-position: 0px -228px;

            opacity: 1;
            transition: $transition-sprites;
        }

        &::after {
            content: "";

            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 38px;

            background-image: url(../images/sprites/icons.png);
            background-repeat: no-repeat;
            background-position: -38px -228px;

            opacity: 0;
            transition: $transition-sprites;
        }

        &:hover {
            &::before {
                opacity: 0;
                transition: $transition-sprites;
            }

            &::after {
                opacity: 1;
                transition: $transition-sprites;
            }
        }
    }

    &.button-download {
        position: relative;

        display: inline-block;

        background-color: $header-dark;
        border-color: $header-dark;
        color: $white !important;

        padding-left: 50px;

        &::before {
            content: "";

            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 38px;

            background-image: url(../images/sprites/icons.png);
            background-repeat: no-repeat;
            background-position: -38px -646px;

            opacity: 1;
            transition: $transition-sprites;
        }

        &::after {
            content: "";

            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 38px;

            background-image: url(../images/sprites/icons.png);
            background-repeat: no-repeat;
            background-position: 0 -646px;

            opacity: 0;
            transition: $transition-sprites;
        }

        &:hover {
            background-color: $white;
            color: $header-dark !important;

            &::before {
                opacity: 0;
                transition: $transition-sprites;
            }

            &::after {
                opacity: 1;
                transition: $transition-sprites;
            }
        }
    }

    &.button-shoppingcart {
        background-color: $header-dark;
        border-color: $header-dark;
        color: $white !important;

        background-image: url(../images/icons/w_warenkorb.png);
        background-repeat: no-repeat;
        background-position: 17px center;
        padding-left: 50px;

        &:hover {
            background-color: $white;
            color: $header-dark !important;

            background-image: url(../images/icons/warenkorb.png);
        }
    }

    &.button-delete-shoppingcart {
        padding-left: 50px;
        background-image: url(../images/icons/delete.png);
        background-repeat: no-repeat;
        background-position: 17px center;
        background-size: auto 18px;

        &:hover {
            background-color: $red;
            border-color: $red !important;
            color: $white;
            background-image: url(../images/icons/w_delete.png);
        }
    }

    &.button-view {
        display: inline-block;
        padding-left: 40px;

        background-image: url(../images/icons/view.png);
        background-repeat: no-repeat;
        background-position: 15px center;

        &:hover {
            background-image: url(../images/icons/w_view.png);
        }
    }

    &.button-attribute {
        padding: 1px 10px;
    }
}

input[type="button"] {
    &:hover {
        background-color: $tuerkis;
        color: $white !important;

        transition: $transition-slow;
    }
}

.button {
    display: block;
    text-decoration: none !important;

    &:hover {
        background-color: $tuerkis;
        color: $white !important;
        text-decoration: none !important;

        transition: $transition-slow;
    }

    &:active,
    &:focus {
        color: $tuerkis;
    }
}

button {
    &.open-modal {
        padding: 0;
        border: 0;
        background-color: $transparent;
    }

    &:focus,
    &:active {
        outline: none !important;
    }
}

.button-small {
    padding: 5px 15px !important;
    margin-top: 0 !important;
}
