/* ------------- COLORS ------------- */

$white: #ffffff;
$black: #000000;

$yellow: #f0c417;
$red: #f15a5a;
$tuerkis: #76c5bf;
$green: #55b257;
$darkblue: #3c5583;

//$tuerkis: #078ccb;

$header-dark: #404656;
$header-light: #515969;

$grey-ultralight: #f7f7f7;
$grey-light: #ececec;
$grey-middle: #a1a1a1;
$grey-dark: #878787;
$grey-ultradark: #333333;

$transparent: transparent;

/* ------------- FONTS ------------- */

$font-lato: "lato", sans-serif;

$font-light: 100;
$font-regular: 400;
$font-bold: 800;
$font-black: 900;

/* ------------- TRANSITION ------------- */

$transition-slow: all 0.35s ease-in-out;
$transition-height: max-height 1s ease;
$transition-margin: margin 0.35s ease-in-out;
$transition-backgroundcolor: background-color 0.35s ease-in-out;
$transition-opacity: opacity 0.35s ease-in-out;
$transition-boxshadow: box-shadow 0.5s;

$transition-width: width 0.5s ease-in-out;
$transition-width-slow: width 5s ease-in-out;

$transition-padding: padding 0.5s ease-in-out;
$transition-sprites: opacity 0.5s;
