@import "_variables.scss";

.modal-header {
    position: relative;
    display: block;

    height: 100px;
    padding: 25px 15px 20px 15px;
    margin: 0 15px;

    border-bottom: 0;

    .modal-title {
        font-size: 24px;
        color: $tuerkis;
        font-weight: $font-bold;
        text-transform: uppercase;
        padding-right: 25px;

        height: 35px;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .modal-pgnr {
        font-size: 12px;
        margin-top: 2px;
        margin-bottom: 0;
    }

    .btn-close {
        position: relative;
        float: right;
        background: none;

        margin-top: 5px;
        padding: 0;
        font-size: 30px;

        color: $header-dark;
        opacity: 1;

        transition: $transition-slow;

        &:hover {
            color: $tuerkis;
            transition: $transition-slow;
        }
    }

    .modal-info,
    .modal-print {
        position: relative;

        float: right;
        margin-top: 16.5px;
        margin-right: 10px;

        height: 22px;
        width: 22px;

        cursor: pointer;
        transition: $transition-slow;

        background-image: url(../images/sprites/content-header.png);
        background-size: 60px;
        background-repeat: no-repeat;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: url(../images/sprites/content-header.png);
            background-size: 60px;
            background-repeat: no-repeat;
            opacity: 0;
            transition: $transition-sprites;
        }

        &:hover {
            background-image: none;

            &::after {
                opacity: 1;
                transition: $transition-sprites;
            }
        }
    }

    .modal-info {
        background-position: 0px 1px;

        margin-left: 8px;

        &::after {
            background-position: -30px 1px;
        }
    }

    .modal-print {
        background-position: 0px -29px;

        &::after {
            background-position: -30px -29px;
        }
    }
}

.modal-body {
    padding-top: 0;
    font-size: 14px;
    max-height: 100%;

    .dialog-info {
        height: 0;
        width: 100%;
        margin: 0 0 15px 0;
        padding: 0 25px;

        overflow: hidden;
        border: 1px solid $tuerkis;
        background-color: rgba($tuerkis, 0.1);

        > div {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        h4 {
            font-weight: $font-bold;
            letter-spacing: 1px;
            text-transform: uppercase;

            margin: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    // Logindaten aendern
    .error-box {
        border: 1px solid $red;
        background-color: rgba($red, 0.05);
        padding: 20px;
        padding-right: 30%;
        color: $red;
        text-align: left;
        margin: 0;

        font-size: 14px;
        line-height: 1.42857143; /* Bootstrap Standard Line-height */

        background-image: url(../images/backgrounds/error.svg);
        background-position: right bottom;
        background-size: auto 90%;
        background-repeat: no-repeat;

        h5 {
            margin: 0;
            font-size: 14px;
        }

        .error-nr {
            font-weight: $font-bold;
            font-size: 14px;
            line-height: normal;
        }
    }

    .dialog-content {
        > h4 {
            font-size: 16px;
            font-weight: $font-bold;

            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;

            padding: 0 15px;
            margin: 20px 0;
        }

        .twitter-typeahead {
            width: 100%;
        }
    }

    .dataTable {
        max-width: 100% !important;
    }
}

.modal-footer {
    background-color: $grey-ultralight;
    border-top: 2px solid $grey-light;
    border-radius: 0px 0px 6px 6px;

    input[type="button"] {
        border-color: $grey-light !important;
        color: $header-dark !important;
        font-weight: $font-bold;
        margin-left: 10px;

        -webkit-box-shadow: none !important;
        box-shadow: none !important;

        &:hover {
            border-color: $tuerkis !important;
            color: $white !important;
        }

        &:focus {
            border-color: rgba($tuerkis, 0.8) !important;
            outline: 0 none !important;

            -webkit-box-shadow: 0 1px 1px rgba($tuerkis, 0.075) inset,
                0 0 8px rgba($tuerkis, 0.6) !important;
            box-shadow: 0 1px 1px rgba($tuerkis, 0.075) inset,
                0 0 8px rgba($tuerkis, 0.6) !important;

            transition: border-color 0.35s, box-shadow 0.35s, outline 0.35s;
        }

        &.button-shoppingcart {
            color: $white !important;
            border-color: $header-dark;

            &:hover {
                color: $header-dark !important;
                border-color: $header-dark !important;
            }
        }
    }
}

/* ------------- MAIL DIALOG ------------- */

.dialog-row {
    position: relative;

    padding: 20px;
    border-bottom: 1px solid $grey-light;

    transition: $transition-slow;

    &:nth-child(even) {
        background-color: $grey-ultralight;
    }

    &:last-child {
        border-bottom: 0;
    }

    &:hover {
        background-color: rgba($tuerkis, 0.1);
    }

    .delivery-img {
        width: 45px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 15px;
    }

    .profile-img {
        float: left;
        border: 5px solid $grey-light;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin-right: 15px;
        overflow: hidden;
        position: relative;

        img {
            min-width: 100%;
            min-height: 100%;
            max-height: 150%;
            max-width: 150% !important;

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &.avatar {
                width: 100%;
            }
        }
    }

    .delivery-option {
        padding-left: 70px;
    }

    .contact-infos {
        margin-top: 10px;
        margin-bottom: 10px;
        display: inline-block;
        width: calc(100% - 130px);
        overflow: hidden;
        text-overflow: ellipsis;

        strong {
            text-transform: uppercase;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.no-img .contact-infos {
        width: calc(100% - 50px);
    	vertical-align: middle;
    }
	
	&.inactive {
		color: $grey-middle;
	}
	
	.info-icon {
		cursor: pointer;
		display: inline-block;
		width: 25px;
		height: 25px;
		border: 2px solid $grey-middle;
		border-radius: 50%;
		color: $grey-middle;
		line-height: 21px;
		font-weight: $font-bold;
		text-align: center;
		margin-left: 12.5px;
	}
}

// dialog-row Buttons
.dialog-row {
    .mail-button,
    .send-wk-button,
    .change-wk-button,
    .choose-address-button,
    .choose-commission-button,
    .info-wk-button {
        position: relative;

        display: block;
        width: 40px;
        height: 40px;

        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);

        border-radius: 20px;
        border: 1px solid $header-dark;

        background-color: $white;

        background-image: url(../images/sprites/icons.png);
        background-repeat: no-repeat;

        cursor: pointer;
        transition: $transition-slow;

        &::after {
            content: "";

            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            background-image: url(../images/sprites/icons.png);
            background-repeat: no-repeat;

            opacity: 0;
            transition: $transition-sprites;
        }

        &:hover {
            background-image: none;
            background-color: $tuerkis;
            color: $white;
            border: 1px solid $tuerkis;

            &::after {
                opacity: 1;
                transition: $transition-sprites;
            }
        }
    }
    .info-wk-button {
        background-image: url(../images/sprites/content-header.png);
        background-position: 2px 2px;
        margin-left: 8px;
        border: none;

        &::after {
            content: "";

            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            background-image: url(../images/sprites/content-header.png);
            background-repeat: no-repeat;

            opacity: 0;
            transition: $transition-sprites;
        }
        &:hover {
            background-color: $white;
            color: $tuerkis;
            border: none;

            &::after {
                opacity: 1;
                transition: none;
            }
        }
    }   
    .mail-button {
        background-position: 0px -304px;

        &::after {
            background-position: -38px -304px;
        }
    }

    .send-wk-button {
        background-position: 0px -456px;

        &::after {
            background-position: -38px -456px;
        }
    }

    .change-wk-button {
        background-position: 0px -76px;

        &::after {
            background-position: -38px -76px;
        }
    }

    .choose-address-button {
        background-position: 0px -114px;

        &::after {
            background-position: -38px -114px;
        }
    }

    .choose-commission-button {
        background-position: 0px -152px;

        &::after {
            background-position: -38px -152px;
        }
    }

    .mail-sent {
        float: right;
        margin-left: 10px;
        margin-top: 23.5px;
        margin-bottom: 23.5px;
    }
}

/* ------------- ERROR DIALOG ------------- */

.error-dialog {
    .modal-header {
        height: auto;

        h4,
        .modal-error-nr {
            color: $red !important;
        }

        .modal-error-nr {
            margin-left: 15px;
            font-size: 16px;
        }

        .modal-print,
        .modal-info {
            display: none;
        }

        .btn-close {
            &:hover {
                color: $red;
            }
        }
    }

    .modal-body {
        -webkit-box-shadow: inset 0px -10px 20px 0px rgba($red, 0.1);
        box-shadow: inset 0px -10px 20px 0px rgba($red, 0.1);

        background-image: url(../images/backgrounds/error.svg);
        background-position: right bottom;
        background-repeat: no-repeat;

        background-size: auto 185px;
        min-height: 200px;

        .dialog-info {
            border-color: $red !important;
        }

        .dialog-content {
            width: 75%;
            padding: 80px 15px;
        }

        .ansprechpartner {
            p {
                margin-bottom: 5px;
            }

            h4 {
                font-size: 20px;
                margin-bottom: 10px;
                margin-top: 20px;
            }
        }
    }

    .close-button:hover {
        background-color: $red !important;
        border-color: $red !important;
    }
}

/* ------------- INPUT ELEMENTS ------------- */

.modal-dialog {
    .new-element {
        padding-top: 50px;
        padding-bottom: 50px;

        .text-container,
        .input-container {
            margin-bottom: 5px;
        }
    }

    .details {
        padding-top: 30px;
        padding-bottom: 30px;

        input[type="button"] {
            margin-top: 20px;
        }

        .checkbox-container {
            .checkbox {
                margin: 5px 10px 5px 0;
            }

            > label {
                width: calc(100% - 40px);
            }
        }
    }
}

.modal-dialog {
    /* ------------- ONLINE PLUS KALKULATION ------------- */

    .kalkulation {
        padding-top: 30px;
        padding-bottom: 30px;

        .switch {
            float: none;
            vertical-align: middle;
        }

        .info-icon {
            vertical-align: middle;
        }

        input[type="button"] {
            padding: 5px 15px;
            vertical-align: middle;
            height: 32px;
        }

        .setting-percentage {
            vertical-align: middle;
        }
    }

    /* ------------- KONTAKT BEARBEITEN ------------- */

    .user-img {
        height: 150px;
        width: 150px;

        margin: 0 auto 10px;

        border-radius: 50%;
        border: 5px solid $grey-light;

        position: relative;
        overflow: hidden;

        img {
            min-width: 100%;
            min-height: 100%;
            max-height: 150%;
            max-width: 150%;

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &.avatar {
                width: 100%;

                transform: none;
                left: 0;
                top: 0;
            }
        }
    }

    .edit-contact-textarea {
        height: 72px;
    }
}

/* ------------- EKKO-LIGHTBOX ------------- */

.ekko-lightbox {
    .modal-header {
        height: auto;

        .modal-print {
            margin-top: 8px;
        }

        .btn-close {
            margin-top: 1px;
        }
    }

    .modal-footer {
        justify-content: flex-start;
        font-size: 14px;
    }

    .ekko-lightbox-container {
        height: auto !important;

        .ekko-lightbox-item {
            position: static !important;
            text-align: center;

            img {
                width: auto !important;
                max-width: 100%;
            }

            &:not(.show) {
                display: none;
            }
        }
    }

    .ekko-lightbox-nav-overlay a span {
        color: $header-dark;
        padding: 0 20px;
    }
}

.modal-symbol-pic {
    padding: 15px;
    min-height: 55px;

    .notes-icon {
        left: 15px;
    }

    p {
        padding-left: 35px;
        margin: 3px 0 0 0;
        line-height: 18px;
        color: $header-dark;
    }
}

/* ------------- SONDERLAGER BESTELLEN ------------- */

.shop-sonderlager {
    padding-top: 15px;
    padding-bottom: 15px;

    .main-img {
        margin-bottom: 14px;
    }

    .main-img a,
    .small-imgs a {
        display: block;
        overflow: hidden;
        margin-bottom: 6px;
        border: 1px solid $grey-light;

        -webkit-box-shadow: 0 0 20px 0 rgba($black, 0.1);
        box-shadow: 0 0 20px 0 rgba($black, 0.1);
    }

    .product-img {
        width: 100%;
    }

    .detail-group {
        overflow: auto;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $grey-middle;

        h1 {
            font-size: 22px;
            font-weight: $font-bold;
            margin: 0;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

/* ------------- DATATABLE ------------- */

.dialog-content {
    .dataTable_footer {
        > div.col-sm-4:nth-child(1),
        > div.col-sm-4:nth-child(2) {
            width: 50%;
            float: left;

            .dataTables_info {
                line-height: 34px;
            }

            .dataTables_length {
                text-align: right;
            }
        }

        > div.col-sm-4:nth-child(3) {
            width: 100%;
            float: left;
            margin-top: 10px;

            > div.dataTables_paginate {
                text-align: center;
            }

            .pagination {
                float: none;
            }
        }
    }
}

/* ------------- FIX FOR SCROLL ISSUE ------------- */

.modal {
    overflow: auto !important;
}

/* ------------- MEDIA QUERIES ------------- */

@media (min-width: 768px) {
    .ekko-lightbox .modal-dialog {
        width: 600px !important;
        max-width: 600px !important;
    }
}

@media (max-width: 767px) {
    .ekko-lightbox .modal-dialog {
        width: auto !important;
        max-width: none !important;
    }

    .ekko-lightbox .ekko-lightbox-nav-overlay a {
        width: 45px;
        height: 100%;

        flex: none;
        position: absolute;
        left: 0;

        opacity: 1;
    }

    .ekko-lightbox .ekko-lightbox-nav-overlay a span {
        padding: 0;
        text-align: center !important;
    }

    .ekko-lightbox .ekko-lightbox-nav-overlay a:last-child {
        right: 0;
        left: auto;
    }
}
