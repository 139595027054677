@import "_variables.scss";

.tooltip {
    &.show {
        opacity: 1 !important;
    }

    > .tooltip-arrow,
    > .tooltip-arrow::before {
        color: $tuerkis;
        --bs-tooltip-bg: $tuerkis;
    }

    > .tooltip-inner {
        background-color: $tuerkis;
        color: $white;
        border: 1px solid $tuerkis;
        padding: 5px;
        font-size: 12px;

        border-color: $tuerkis;
        cursor: default;
    }
}

/* ------------- RED/ERROR TOOLTIP ------------- */

.tooltip-error,
.tooltip-red {
    > .tooltip-arrow,
    > .tooltip-arrow::before {
        color: $red;
        --bs-tooltip-bg: $red;
    }

    > .tooltip-inner {
        background-color: $red;
        border: 1px solid $red;
        border-color: $red;
    }
}

/* ------------- YELLOW TOOLTIP ------------- */

.tooltip-yellow {
    > .tooltip-arrow,
    > .tooltip-arrow::before {
        color: $yellow;
        --bs-tooltip-bg: $yellow;
    }

    > .tooltip-inner {
        background-color: $yellow;
        border: 1px solid $yellow;
        border-color: $yellow;
    }
}

/* ------------- BLUE TOOLTIP ------------- */

.tooltip-blue {
    > .tooltip-arrow,
    > .tooltip-arrow::before {
        color: $header-dark;
        --bs-tooltip-bg: $header-dark;
    }

    > .tooltip-inner {
        background-color: $header-dark;
        border: 1px solid $header-dark;
        border-color: $header-dark;
    }
}

/* ------------- GREEN TOOLTIP ------------- */

.tooltip-green {
    > .tooltip-arrow,
    > .tooltip-arrow::before {
        color: $green;
        --bs-tooltip-bg: $green;
    }

    > .tooltip-inner {
        background-color: $green;
        border: 1px solid $green;
        border-color: $green;
    }
}
