@charset "UTF-8";

/* ---------------------------------------- IMPORT ---------------------------------------- */
@import "_variables.scss";
@import "_modals.scss";
@import "_input.scss";
@import "_buttons.scss";
@import "_tooltips.scss";

html {
    height: 100%;
}

body {
    font-family: $font-lato;
    font-size: 14px;
    color: $header-dark;

    width: 100%;
    height: 100%;
    min-height: 100%;
}

a {
    color: $tuerkis;
    text-decoration: none;

    transition: color 0.35s ease-in-out, text-decoration ease-in-out;

    &:visited {
        text-decoration: none;
    }

    &:hover {
        text-decoration: underline;
        color: $header-dark;
        transition: color 0.35s ease-in-out, text-decoration ease-in-out;
    }

    &:focus {
        color: $header-dark;
    }
}

.outer {
    position: absolute;
    left: 0;
    top: 0;

    max-width: 100%;
    width: 100%;
    height: 100%;

    display: table;
}

.inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;

    background-image: url(../images/backgrounds/login_overlay.png),
        url(../images/backgrounds/login.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, 100% 100%;
    background-position: center;
}

.login-form {
    margin: 0 auto;

    max-width: 500px;
    width: 85%;

    border-radius: 10px;
    background-color: $white;
    padding: 15px;

    -webkit-box-shadow: 0px 0px 29px 0px rgba($black, 0.2);
    box-shadow: 0px 0px 29px 0px rgba($black, 0.2);

    .logo {
        border-bottom: 2px solid $tuerkis;

        -webkit-box-shadow: 0px 1px 0px 0px $white,
            inset 0px -1px 0px 0px $white, 0px 20px 50px -15px rgba($black, 0.1);
        box-shadow: 0px 1px 0px 0px $white, inset 0px -1px 0px 0px $white,
            0px 20px 50px -15px rgba($black, 0.1);

        img {
            width: 40%;
            padding-bottom: 15px;
        }
    }
}

.login-as {
    font-weight: $font-bold;
    display: block;
    padding: 15px 0;
}

.user-img {
    float: left;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: 5px solid $grey-light;
    position: relative;
    overflow: hidden;

    img {
        min-width: 100%;
        min-height: 100%;
        max-height: 150%;
        max-width: 150%;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.avatar {
            width: 100%;
            left: 0;
            top: 0;
            transform: none;
        }
    }
}

.user-list {
    border: 1px solid $grey-light;
    border-radius: 5px;

    .dropdown-toggle {
        font-size: 14px;
        width: 100%;
        text-align: left;
        padding: 15px 10px;
        border-radius: 0 0 6px 6px;
        border: 0;
        text-transform: uppercase;
        background-color: $grey-ultralight;

        transition: $transition-slow;

        &:hover {
            background-color: $grey-ultralight;
            color: $header-dark;
            transition: $transition-slow;
        }

        &:active {
            color: $header-dark;

            -webkit-box-shadow: none;
            box-shadow: none;
            transition: $transition-slow;
        }
    }

    .dropdown-menu {
        width: 100%;
        padding: 0;

        max-height: 202px;
        overflow-y: auto;

        li {
            a {
                padding: 10px 20px;
                border-bottom: 1px solid $grey-light;
            }

            &:last-child a {
                border-bottom: 0;
            }
        }

        strong {
            display: block;
        }
    }

    .login-row {
        display: block;
        padding: 12.5px;
        border-bottom: 1px solid $grey-light;
        height: 100px;
        color: $header-dark;
        transition: $transition-slow;

        &:hover {
            background-color: $grey-light;
            transition: $transition-slow;
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-child {
            border-bottom: 0;
        }

        .contact-infos {
            margin: 17.5px 15px;
            width: calc(100% - 150px);
            overflow: hidden;

            float: left;

            strong,
            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            strong {
                text-transform: uppercase;
            }
        }

        .delete {
            position: relative;

            float: left;
            cursor: pointer;

            width: 40px;
            height: 40px;
            margin-top: 17.5px;

            border: 1px solid $header-dark;
            border-radius: 20px;

            -webkit-box-shadow: 0px 0px 5px 0px rgba($black, 0.1);
            box-shadow: 0px 0px 5px 0px rgba($black, 0.1);

            background-image: url(../images/sprites/icons.png);
            background-position: 0px -190px;

            transition: $transition-slow;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                background-repeat: no-repeat;
                background-image: url(../images/sprites/icons.png);
                background-position: -38px -190px;

                opacity: 0;
                transition: $transition-sprites;
            }

            &:hover {
                background-color: $red;
                border-color: $red;
                background-image: none;

                transition: $transition-slow;

                &::after {
                    opacity: 1;
                    transition: $transition-sprites;
                }
            }
        }
    }
}

.new-user {
    display: block;
    overflow: hidden;
    height: 100px;
    border-radius: 50px;

    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 30px;

    border: 1px solid $tuerkis;

    -webkit-box-shadow: 0px 0px 9px 0px rgba($tuerkis, 0.35);
    box-shadow: 0px 0px 9px 0px rgba($tuerkis, 0.35);

    transition: $transition-slow;

    &:hover {
        text-decoration: none;
        background-color: $tuerkis;
        transition: $transition-slow;

        h1,
        h2 {
            color: $white;
            transition: $transition-slow;
        }
    }

    h1,
    h2 {
        width: calc(100% - 100px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    h1 {
        color: $tuerkis;
        font-size: 16px;
        font-weight: $font-bold;
        text-transform: uppercase;

        margin-bottom: 5px;
        margin-top: 32px;
    }

    h2 {
        margin: 0;
        font-size: 12px;
    }

    .user-img {
        float: right;
        margin: 11px;
    }
}

.login-footer {
    background-color: $grey-ultralight;
    border-top: 2px solid $grey-light;

    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    border-radius: 0px 0px 10px 10px;
    padding: 15px;

    overflow: auto;
}

.error-box {
    border: 1px solid $red;
    background-color: rgba($red, 0.05);
    padding: 20px;
    padding-right: 30%;
    margin-top: 25px;
    color: $red;

    background-image: url(../images/backgrounds/error.svg);
    background-position: right bottom;
    background-size: auto 90px;
    background-repeat: no-repeat;

    a {
        color: $header-dark;
        transition: $transition-slow;

        &:hover {
            transition: $transition-slow;
        }
    }

    h5 {
        margin: 0;
        font-size: 14px;
    }

    .error-nr {
        font-weight: $font-bold;
    }
}

.login-fields {
    overflow-x: visible;
    overflow-y: auto;

    margin-left: -15px;
    margin-right: -15px;
    padding: 25px 15px 0 15px;

    > div {
        margin-bottom: 10px;

        > label {
            padding-left: 10px;
            font-weight: $font-regular;
            cursor: pointer;
        }
    }

    .checkbox {
        margin-left: 15px;
    }
}

.mobile-link {
    padding-bottom: 15px;
}

.birthday-container {
    input[type="text"] {
        margin-right: 10px;
        display: inline-block;
        width: auto;

        &::last-child {
            margin-right: 0;
        }
    }
}

input[type="text"],
input[type="password"] {
    display: block;
    height: 42px;
    width: 100%;
    margin-bottom: 15px;

    border-radius: 21px;
}

input[type="button"] {
    margin-left: 10px;
    margin-bottom: 10px;
}

@media (max-width: 485px) {
    body {
        height: auto;
    }

    .outer,
    .inner {
        display: block;
    }

    .inner {
        padding: 20px 0;
    }

    .login-form .logo img {
        width: 100%;
    }

    .login-row {
        .user-img {
            display: none;
        }

        .contact-infos {
            width: calc(100% - 60px) !important;
            margin-left: 0 !important;
        }
    }

    .error-box {
        padding: 20px;
        background-image: none;
    }
}

@media print {
    .login-form {
        a::after {
            display: none;
        }
    }
}
