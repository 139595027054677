@import "_variables.scss";

input,
textarea {
    transition: border-color 0.35s, box-shadow 0.35s, outline 0.35s;

    &:focus {
        border-color: rgba($tuerkis, 0.8) !important;
        outline: 0 none !important;

        -webkit-box-shadow: 0 1px 1px rgba($tuerkis, 0.075) inset,
            0 0 8px rgba($tuerkis, 0.6) !important;
        box-shadow: 0 1px 1px rgba($tuerkis, 0.075) inset,
            0 0 8px rgba($tuerkis, 0.6) !important;

        transition: border-color 0.35s, box-shadow 0.35s, outline 0.35s;
    }

    &:invalid,
    &.error {
        border-color: rgba($red, 0.8) !important;
        outline: 0 none !important;

        -webkit-box-shadow: 0 1px 1px rgba($red, 0.075) inset,
            0 0 8px rgba($red, 0.6) !important;
        box-shadow: 0 1px 1px rgba($red, 0.075) inset, 0 0 8px rgba($red, 0.6) !important;

        transition: border-color 0.35s, box-shadow 0.35s, outline 0.35s;
    }

    &:disabled {
        background-color: $grey-light !important;
    }
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="search"] {
    border-radius: 15px;
    height: 32px;
    border: 1px solid $grey-middle;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $white;
    max-width: 100%;
    line-height: normal;

    color: $header-dark;
    font-size: 14px;
}

input[type="button"] {
    &.button-no-margin-left {
        margin-left: 0 !important;
    }
}

textarea {
    border-radius: 15px;
    border: 1px solid $grey-middle;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $white;
    max-width: 100%;

    resize: none;
    vertical-align: top;
    width: 400px;
    max-width: 100%;

    color: $header-dark;
    font-size: 14px;
}

/* ------------- TOGGLE ------------- */

.switch {
    position: relative;
    width: 60px;
    height: 32px;
    float: left;
    margin: 0;

    input {
        display: none;

        &:checked + .bg {
            background-color: $tuerkis;
            border: 1px solid $tuerkis;
        }

        &:focus + .bg {
            -webkit-box-shadow: 0 0 1px $tuerkis;
            box-shadow: 0 0 1px $tuerkis;
        }

        &:checked + .bg::before {
            -webkit-transform: translateX(27px);
            -ms-transform: translateX(27px);
            transform: translateX(27px);
        }
    }

    .bg {
        position: absolute;
        cursor: pointer;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: $grey-middle;
        border: 1px solid $grey-middle;

        border-radius: 34px;

        transition: $transition-slow;

        &::before {
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            left: 3px;
            bottom: 3px;

            border-radius: 50%;
            background-color: $white;

            transition: $transition-slow;
        }
    }
}

/* ------------- CHECKBOX ------------- */

.checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0;
    vertical-align: top;

    input[type="checkbox"] {
        position: absolute !important;
        margin: 0;
        width: 20px;
        height: 20px;
        left: 0;
        opacity: 0;
        z-index: 2;
        cursor: pointer;

        &:checked + label {
            background-color: $tuerkis;
            border: 1px solid $tuerkis;

            transition: $transition-slow;
        }

        &:checked + label::after {
            opacity: 1;
            transition: $transition-slow;
        }
    }

    label {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;

        border-radius: 4px;
        border: 1px solid $grey-dark;

        transition: $transition-slow;

        &::after {
            content: "";
            opacity: 0;

            position: absolute;
            width: 10px;
            height: 5px;
            background: transparent;
            top: 6px;
            left: 4px;
            border: 2px solid $white;
            border-top: none;
            border-right: none;

            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
}

/* ------------- RADIOBUTTON ------------- */

.radio-group {
    display: inline-block;
    margin-right: 15px;
    line-height: normal;
    cursor: pointer;

    > label {
        cursor: pointer;
        margin: 0;
        //    font-size: 12px;
    }
}

.radio-button {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    margin-right: 5px;

    input[type="radio"] {
        position: absolute !important;
        margin: 0;
        width: 20px;
        height: 20px;
        left: 0;
        opacity: 0;
        z-index: 2;
        cursor: pointer;

        &:checked + label {
            background-color: $tuerkis;
            border: 1px solid $tuerkis;

            transition: $transition-slow;
        }

        &:checked + label::after {
            opacity: 1;

            transition: $transition-slow;
        }
    }

    label {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;

        border-radius: 10px;
        border: 1px solid $grey-dark;

        transition: $transition-slow;

        &::after {
            content: "";
            opacity: 0;
            border: 3px solid $white;

            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;

            top: 1px;
            left: 1px;
        }
    }
}

/* ------------- FILE INPUT ------------- */

.file-upload {
    display: inline-block;

    input[type="file"] {
        height: 0px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        width: 0px;
        z-index: 1;
    }

    input[type="file"] + label {
        cursor: pointer;
        display: inline-block;
        position: relative;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        padding-left: 52px;
        padding-right: 25px;

        max-width: 200px;
        height: 40px;
        line-height: 38px;

        border-radius: 20px;
        border: 1px solid $tuerkis;
        background-color: $white;
        color: $tuerkis;

        -webkit-box-shadow: 0px 0px 5px 0px rgba($black, 0.1);
        box-shadow: 0px 0px 5px 0px rgba($black, 0.1);

        transition: $transition-slow;

        &:hover {
            background-color: $tuerkis;
            color: $white;

            transition: $transition-slow;

            .layer-top {
                opacity: 0;
                transition: $transition-opacity;
            }

            .layer-bottom {
                opacity: 1;
                transition: $transition-opacity;
            }
        }

        img {
            width: 20px;
            position: absolute;
            left: 25px;
            top: 10px;
            margin-right: 7px;

            &.layer-bottom {
                opacity: 0;
                transition: $transition-opacity;
            }
        }
    }
}

/* ------------- INPUT WITH CLEAR BUTTON ------------- */

.input-with-clear {
    position: relative;
    display: inline-block;

    input[type="text"] {
        padding-right: 35px;
    }

    .clear-button {
        visibility: hidden;
        opacity: 0.5;
        background-image: url(../images/sprites/icons.png);
        background-repeat: no-repeat;
        background-position: -3px -30px;
        background-size: 80px;
        border: 0;
        position: absolute;
        top: 6px;
        right: 10px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        width: 20px;
        height: 20px;
        padding: 0;
        &:hover {
            background-color: $transparent !important;
        }
    }

    &.btnclear-visible {
        .clear-button {
            visibility: visible;
        }
    }
}
